<template>
  <div>
    <el-form :model="search">
      <el-row :gutter="20">
        <el-col :span="4">
          <el-form-item label="请输入顾客昵称">
            <el-input v-model="search.name" placeholder="请输入顾客昵称" />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="请输入手机号">
            <el-input v-model="search.phone" placeholder="请输入手机号" />
          </el-form-item>
        </el-col>
        <el-col :span="2">
          <el-form-item>
            <el-button type="primary" @click="onSearchBtn" :icon="Search"
              >搜索</el-button
            >
          </el-form-item>
        </el-col>
      <!--  <el-col :span="1">
          <el-button
            :loading="loading"
            type="success"
            @click="onCustomerexport"
          >
            导出
          </el-button>
        </el-col> -->
      </el-row>
    </el-form>

    <!-- 表格 -->

    <el-table
      :data="listData"
      border
      style="width: 100%"
      :header-cell-style="{ background: '#f2f2f2' }"
    >
      <el-table-column prop="id" label="序号" width="100px" />
      <el-table-column label="头像" width="180px">
        <template #default="{ row, $index }">
          <el-image
            @click="previewUrl($index)"
            style="width: 50px; height: 50px"
            :preview-teleported="true"
            :src="row.pic"
            :initial-index="imgIndex"
            fit="cover"
            :preview-src-list="srcList"
          />
        </template>
      </el-table-column>
      <el-table-column prop="nickname" label="昵称" width="200px" />
      <!-- <el-table-column prop="name" label="姓名" width="150px" /> -->
      <el-table-column prop="phone" label="手机号" width="300px" />
      <el-table-column label="用户状态" width="200px">
        <template v-slot="{ row }">
          {{ row.status ? '已冻结' : '正常' }}
        </template>
      </el-table-column>
      <el-table-column prop="add_time" label="添加时间" width="200px" />
    </el-table>

    <!-- 分页 -->

    <el-row style="margin-top: 20px">
      <el-col>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="search.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="search.total"
        >
        </el-pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
// articleListAPI
import { ref } from 'vue'
import { Search } from '@element-plus/icons-vue'
import { customerListAPI } from '@/api/customer'

/**
 *  表单 S
 */
// 表单数据源
const search = ref({
  class_id: '',
  title: '',
  page: 1,
  limit: 10,
  total: 0
})
// 搜索
const onSearchBtn = () => {
  onListData()
}

/**
 *  表单 E
 */

/*
 *  表格： S
 */

// 列表
const listData = ref([])
const onListData = async () => {
  const { data, total } = await customerListAPI(search.value)
  search.value.total = total
  listData.value = data
}
onListData()

/** 导出 E */
// const loading = ref(false)
// const onCustomerexport = async () => {
//   loading.value = true
//   const data = await reportCustomerAPI(search.value)
//   window.location.href = `https://hxdh.yslts.com${data}`
//   loading.value = false
// }

/*
 *  表格： E
 */

/**
 *  分页 S
 */

// 每页获取多少数据
const handleSizeChange = (val) => {
  search.value.limit = val
  onListData()
}

// 页码值
const handleCurrentChange = (val) => {
  search.value.page = val
  onListData()
}

/**
 *  分页 E
 */
</script>

<style lang="scss" scoped></style>
